import React from "react";
import Table from "./../Components/Table";

export default function Home() {
  return (
    <div>
      <Table />
    </div>
  );
}
